var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"secondary white--text"},[_c('div',{staticClass:"ma-0"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"large":"","left":"","color":"white"}},[_vm._v("mdi-home")]),_c('p',{staticClass:"subtitle-1 my-0"},[_vm._v(_vm._s(_vm.tableData.length)+" Properties")])],1)]),_c('v-spacer'),_c('TableSearch',{on:{"input":function (newText) {_vm.search = newText}}})],1),_c('v-data-table',{staticClass:"tableColor",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.tableData,"items-per-page":10},scopedSlots:_vm._u([{key:"item.PropertyAddress",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"my-0 font-weight-light"},[_vm._v(_vm._s(item.Landlord))]),_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.PropertyAddress))])]}},{key:"item.RentPerCalendarMonth",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-light text-caption"},[_vm._v(_vm._s(item.RentPerCalendarMonth))])]}},{key:"item.ServiceType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-light text-caption"},[_vm._v(_vm._s(item.ServiceType))])]}},{key:"item.TenancyState",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-light text-caption"},[_vm._v(_vm._s(item.TenancyState))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"secondary","dark":"","small":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-0 secondary",attrs:{"dark":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.openDialog(item)}}},[_c('v-list-item-title',[_vm._v("Contact support regarding this property")]),_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-comment-text-outline")])],1)],1)],1)],1)]}}])})],1),_c('MsgDialog',{attrs:{"dialog":_vm.dialog,"details":_vm.selectedItem.PropertyAddress,"source":_vm.source,"OID":_vm.selectedItem.TenancyID},on:{"closeDialog":_vm.closeDialog,"msgSent":_vm.msgSent}}),_c('Snackbar',{attrs:{"snackbarDialog":_vm.snackbar,"snackbarText":_vm.snackbarText,"snackbarColor":_vm.snackbarColor,"timeout":_vm.timeout},on:{"closeSnackbar":function($event){return _vm.closeSnackbar()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }