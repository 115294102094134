<template>
  <div class="">
    <v-container class="py-0">
      <transition name="fade" mode="out-in" appear>
        <PageTitle class="d-block d-sm-none">Properties</PageTitle>
      </transition>

      <transition name="slide-left-fade" mode="out-in" appear>
        <v-tabs
          v-model="tab"
          class="mt-5 mt-sm-1"
          color="tabColor"
          slider-color="tabColor"
          center-active
          background-color="tertiary"
          show-arrows
          height="55"
        >
          <v-tab ripple>
            <span class="d-md-none">Active</span>
            <span class="d-none d-md-block">Active tenancies</span>
          </v-tab>
          <v-tab ripple>
            Rent collected 
          </v-tab>
          <v-tab ripple>
            <span class="">No owner</span>
            <!-- <span class="d-none d-md-block">Without owner </span> -->
          </v-tab>
          <v-tab ripple>
            <span class="">No tenants</span>
            <!-- <span class="d-none d-md-block">Missing tenants</span> -->
          </v-tab>
          <v-tab ripple>
            <span class="">Not collected</span>
            <!-- <span class="d-none d-md-block">Rent not collected</span> -->
          </v-tab>
          <v-tab ripple>
            <span class="">New</span>
            <!-- <span class="d-none d-md-block">New tenancies</span> -->
          </v-tab>
          <v-tab ripple>
            <span class="">Historic</span>
            <!-- <span class="d-none d-md-block">New tenancies</span> -->
          </v-tab>
          <v-spacer></v-spacer>
          <span class="d-none d-sm-block">
            <PageTitle>Properties</PageTitle>
          </span>
        </v-tabs>
      </transition>

      <transition name="slide-fade" mode="out-in" appear>
        <div>
          <v-tabs-items v-model="tab" class="pt-2 tertiary">
            <v-tab-item>
              <Skeleton v-if="!propertiesManagedRecords" />
              <PropertyRecordsTables
                v-if="propertiesManagedRecords"
                :tableData="propertiesManagedRecords"
                :headers="headers"
                msg="Active tenancies"
              />
            </v-tab-item>
            <v-tab-item>
              <Skeleton v-if="!propertiesRentCollectedRecords" />
              <PropertyRecordsTables
                v-if="propertiesRentCollectedRecords"
                :tableData="propertiesRentCollectedRecords"
                :headers="headers"
                msg="Rent collected"
              />
            </v-tab-item> 
            <v-tab-item>
              <Skeleton v-if="!propertiesWithoutOwnerRecords" />
              <PropertyRecordsTables
                v-if="propertiesWithoutOwnerRecords"
                :tableData="propertiesWithoutOwnerRecords"
                :headers="headers"
                msg="Without owner"
              />
            </v-tab-item>  
            <v-tab-item>
              <Skeleton v-if="!propertiesWithoutTenantsRecords" />
              <PropertyRecordsTables
                v-if="propertiesWithoutTenantsRecords"
                :tableData="propertiesWithoutTenantsRecords"
                :headers="headers"
                msg="Missing tenants"
              />
            </v-tab-item>  
            <v-tab-item>
              <Skeleton v-if="!propertiesNotManagedRecords" />
              <PropertyRecordsTables
                v-if="propertiesNotManagedRecords"
                :tableData="propertiesNotManagedRecords"
                :headers="headers"
                msg="Rent not collected"
              />
            </v-tab-item>
            <v-tab-item>
              <Skeleton v-if="!propertiesNewTenanciesRecords" />
              <PropertyRecordsTables
                v-if="propertiesNewTenanciesRecords"
                :tableData="propertiesNewTenanciesRecords"
                :headers="headers"
                msg="New tenancies"
              />     
            </v-tab-item>
            <v-tab-item>
              <Skeleton v-if="!propertiesHistoricRecords" />
              <PropertyRecordsTables
                v-if="propertiesHistoricRecords"
                :tableData="propertiesHistoricRecords"
                :headers="headers"
                msg="Historic"
              />
            </v-tab-item>      
          </v-tabs-items>
        </div>
      </transition>

      <v-row>
        <v-col cols="12">
          <v-expansion-panels multiple class="mt-8">
            <v-expansion-panel>
              <v-expansion-panel-header ripple class="secondary lighten-3 white--text">
                <div class="d-flex align-center justify-start">
                  <!-- <v-icon class="mr-3" color="white">mdi-home</v-icon> -->
                  <v-icon class="mr-3" color="white">mdi-pencil</v-icon>
                  Edit property
                </div>
                <template v-slot:actions>
                  <v-icon color="white">$expand</v-icon>
                </template>
                </v-expansion-panel-header>
              <v-expansion-panel-content>
                <EditProperty />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PropertyRecordsTables from  '../components/Properties/PropertyRecordsTable.vue'
import Skeleton from '../components/SkeletonLoaders/SkeletonLoaderTable.vue'
import EditProperty from '@/components/Edits/EditProperty.vue'
import PageTitle from '@/components/Slots/PageTitle.vue';

export default {
  components: {
    PropertyRecordsTables,
    Skeleton,
    EditProperty,
    PageTitle
  },
  data() {
    return {
      tab: null,
      icon: 'mdi-home',
      managed: true,
      notManaged: true,
      withoutOwner: true,
      rentCollected: true,
      withoutTenants: true,
      newTenancies: true,
      historicTenancies: true,
      headers: [
      {
        text: 'Main Tenant',
        align: 'left',
        value: 'HeadTenant',
      },
      // { text: 'Landlord', value: 'Landlord' },
      { text: 'Landlord/Address', value: 'PropertyAddress' },
      { text: 'Rent', value: 'RentPerCalendarMonth' },
      { text: 'Type', value: 'ServiceType' },
      { text: 'Tenancy', value: 'TenancyState' },
      // { text: 'Support', value: 'Message' },
      { text: '', value: 'actions', align: 'end' },

    ],
    }
  },
  created() {
    this.$store.dispatch('fetchPropertyRecords')
  },
  computed: {
    ...mapState({
      propertiesManagedRecords: state => state.PropertiesRecords.propertiesManagedRecords,
      propertiesRentCollectedRecords: state => state.PropertiesRecords.propertiesRentCollectedRecords,
      propertiesWithoutOwnerRecords: state => state.PropertiesRecords.propertiesWithoutOwnerRecords, 
      propertiesWithoutTenantsRecords: state => state.PropertiesRecords.propertiesWithoutTenantsRecords,
      propertiesNotManagedRecords: state => state.PropertiesRecords.propertiesNotManagedRecords,
      propertiesNewTenanciesRecords: state => state.PropertiesRecords.propertiesNewTenanciesRecords,
      propertiesHistoricRecords: state => state.PropertiesRecords.propertiesHistoricRecords
    })
  }
}
</script>

<style lang="css" scoped>
</style>
