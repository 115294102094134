<template lang="html">
  <div>
    <v-card flat>
      <v-card-title class="secondary white--text">
        <div class="ma-0">
          <div class="d-flex align-center">
            <v-icon large left color="white">mdi-home</v-icon>
            <p class="subtitle-1 my-0">{{ tableData.length }} Properties</p>
          </div>
        </div>
        <v-spacer></v-spacer>
        <TableSearch @input="(newText) => {search = newText}" />
      </v-card-title>

      <v-data-table
        class="tableColor"
        :search="search"
        :headers="headers"
        :items="tableData"
        :items-per-page="10"
      >
        <!-- <template v-slot:item.HeadTenant="{ item }" >
          <span class="accent--text">{{ item.HeadTenant }}</span>
        </template> -->

        <template v-slot:item.PropertyAddress="{ item }" >
          <p class="my-0 font-weight-light">{{ item.Landlord }}</p>
          <p class="my-0">{{ item.PropertyAddress }}</p>
        </template>

        <template v-slot:item.RentPerCalendarMonth="{ item }" >
          <span class="font-weight-light text-caption">{{ item.RentPerCalendarMonth }}</span>
        </template>
        
        <template v-slot:item.ServiceType="{ item }" >
          <span class="font-weight-light text-caption">{{ item.ServiceType }}</span>
        </template>
        
        <template v-slot:item.TenancyState="{ item }" >
          <span class="font-weight-light text-caption">{{ item.TenancyState }}</span>
        </template>

        <!-- Support message dialog with Tooltip -->
        <!-- <template v-slot:item.Message="{ item }" >
          <v-tooltip open-delay="2500" bottom>
            <template v-slot:activator="{ on }">
              <v-btn outlined small @click="openDialog(item)" v-on="on" color="secondary">
                <v-icon small>mdi-comment-text-outline</v-icon>
              </v-btn>
            </template>
            <span>Contact support regarding this property.</span>
          </v-tooltip>
        </template> -->

        <template v-slot:item.actions="{ item }">
          <v-menu  offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                dark
                small
                depressed
                v-bind="attrs"
                v-on="on"
                class="text-capitalize"
              >
                Actions
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="py-0 secondary" dark>
              <v-list-item @click="openDialog(item)">
                <v-list-item-title>Contact support regarding this property</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-comment-text-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

      </v-data-table>
    </v-card>

    <MsgDialog
      :dialog="dialog"
      :details="selectedItem.PropertyAddress"
      :source="source"
      :OID="selectedItem.TenancyID"
      @closeDialog="closeDialog"
      @msgSent="msgSent"
    />

    <Snackbar 
      :snackbarDialog="snackbar" 
      :snackbarText="snackbarText" 
      :snackbarColor="snackbarColor"
      @closeSnackbar="closeSnackbar()"
      :timeout="timeout"
    />

  </div>
</template>

<script>
import MsgDialog from '@/components/Dialogs/MessageDialog'
import Snackbar from '@/components/Snackbars/TheSnackbar'
import TableSearch from '@/components/TableComponents/TableSearch.vue'

export default {
  components: {
    MsgDialog,
    Snackbar,
    TableSearch
  },
  props: ['tableData', 'headers', 'msg'],
  data() {
    return {
      source: 'Properties',
      loading: false,
      search: '',
      selectedItem: '',
      dialog: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      timeout: 3000,
    }
  },
  methods: {
    closeSnackbar() {
      this.snackbar = false
    },
    openDialog(item) {
      this.selectedItem = item
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
      this.msgText = ''
    },
    msgSent(payload) {
      this.dialog = false
      this.snackbar = true,
      this.snackbarColor = payload.color
      this.snackbarText = payload.text
    },   
  }
}
</script>

<style lang="css" scoped>
</style>
